import {
  doc,
  query,
  where,
  setDoc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc as update,
  writeBatch,
  DocumentReference,
  documentId,
  CollectionReference,
} from 'firebase/firestore';

import _ from 'lodash';

import { firestore, auth } from '../../config/firebase';

function getUID() {
  return auth?.currentUser?.uid;
}

function filterUndefined(data: any) {
  return Object.fromEntries(
    Object.entries(data).filter(([key, value]) => value !== undefined)
  )
}

export async function createDoc(data: any, ref: CollectionReference) {
  try {
    const { id, ...rest } = filterUndefined(data)
    const docRef = await addDoc(ref, 
      {
        ...rest,
        author: getUID(),
        date: new Date()
      }
    )
    console.log("Document added with ID: ", docRef.id)
  } catch (e) {
    console.log("Error adding document: ", e)
  }
}

export async function updateDoc(data: any, ref: DocumentReference) {
  return update(ref, data)
}

export async function logExist(ref: CollectionReference, log: string) {
  const logRef = query(ref, where('message', '==', log))
  const logSnap = await getDocs(logRef)
  const size = await logSnap.size
  return size > 0
}

export async function createDocWithId(data: any, ref: DocumentReference) {
  try {
    const { id, ...rest } = filterUndefined(data)
    await setDoc(ref, {
      ...rest,
      author: getUID(),
      date: new Date()
    })
    console.log("Document set successfully")
  } catch (e) {
    console.log("Error adding document: ", e)
  }
}

export async function getDocsWithId(ids: Array<string>, ref: CollectionReference) {
  try {
    const docQuery = query(ref, where(documentId(), 'in', ids))
    const result: Array<any> = []
    const docSnapshot = await getDocs(docQuery)
    docSnapshot.forEach((snapshot) => {
      result.push(snapshot.data())
    })
    return result
  } catch (e) {
    console.log("Error getting documents: ", e)
  }
}

export async function batchCreate(items: Array<any>) {
  return new Promise ((resolve, reject) => {
    try {
      _.chunk(items, 500).forEach(async (chunk) => {
        const batch = writeBatch(firestore)
    
        chunk.forEach((item) => {
          const { data, ref } = item;
          const docRef = doc(ref)
          // Filter out ID as it could be null
          const { id, ...rest } = filterUndefined(data);
          batch.set(docRef, {
            ...rest,
            author: getUID(),
            date: new Date()
          })
        })

        // Commit the batch
        await batch.commit()
      })
      resolve(true)
    } catch (e) {
      reject(e)
    }
  })

  
}

export async function batchCreateWithId(items: Array<any>) {
  return new Promise((resolve, reject) => {
    try {
      _.chunk(items, 500).forEach(async (chunk) => {
        const batch = writeBatch(firestore)
  
        chunk.forEach((item) => {
          const { data, ref } = item;
          // Filter out ID from the object as it's used as Document ID
          const { id, ...rest } = filterUndefined(data);
          batch.set(ref, {
            ...rest,
            author: getUID(),
            date: new Date()
          })
        })
  
        // Commit the batch
        await batch.commit();
      })
      resolve(true)
    } catch (e) {
      reject(e)
    }
  })
}

export async function docExists(ref: DocumentReference) {
  const docSnapshot = await getDoc(ref)
  return docSnapshot.exists()
}