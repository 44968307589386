import { packageLogsCollection } from "@/services/db/dataPoint";
import useFetchItems from "@/services/db/useFetchItems";
import { PackageLogType } from "@/types/package-log";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "@/services/firebaseStorage";
import { Button } from '@mui/material';

import BasicList from "../ui/list";

interface PackageDetailListProps {
  itemId: string;
  imageFilePath?: string,
  localImageFilePath?: string,
}

const PackageDetailList = ({itemId, imageFilePath, localImageFilePath}: PackageDetailListProps) => {
  const { t } = useTranslation();
  const [logs, setLogs] = useState<PackageLogType[]>([]);
  const [imageUrl, setImageUrl] = useState('');

  const ref = packageLogsCollection(itemId);
  const { data } = useFetchItems({
    itemsPerPage: 100,
    ref: ref
  })

  useEffect(() => {
    if (data) {
      setLogs(data.docs.map((doc) => doc.data() as PackageLogType) ?? []);
    }
  }, [data])

  useEffect(() => {
    if (localImageFilePath) {
      setImageUrl(localImageFilePath)
    } else if (imageFilePath) {
      // Only show thumbnail on load
      const splitUrl  = imageFilePath.replace('images', 'images/thumbnails').split('.')
      const ext       = splitUrl.pop() 
      const thumbnailUrl = splitUrl.join('.') + '_500x500.' + ext
      getFileUrl(thumbnailUrl).then((result) => {
        setImageUrl(result as string)
      })
    }
  }, [imageFilePath, localImageFilePath])

  const handleDownloadImage = useCallback(() => {
    getFileUrl(imageFilePath as string).then(async (url) => {
      // Download the image as blob
      const response = await fetch(url as string);
      const blob = await response.blob();

      // Create a new anchor element
      const anchor = document.createElement('a');

      // Set the href attribute to the image blob URL
      anchor.href = URL.createObjectURL(blob);

      // Set the download attribute to the image name
      anchor.download = imageFilePath?.split('/').pop() as string;

      // Trigger the click event
      anchor.click();
    })
  }, [imageFilePath])
  
  return(
    <div className="w-[300px] md:w-[550px]">
      <p className="text-center text-xl md:text-2xl">
        {`${t('package details for')} ${itemId}`}
      </p>
      <BasicList 
        items={logs}
        primary="message"
        secondary="date"
      />
      <div className="w-full flex flex-col items-center">
        {
          imageUrl && (<>
            <img 
              src={imageUrl as string} 
              alt='Custom' 
              width={400} height={300}
              className="mt-2 rounded-[20px]"
            />
            <Button className="mt-2 normal-case" onClick={handleDownloadImage}>{ t('download full image') }</Button>
          </>)
        }
      </div>
    </div>
    
  )
}

export default PackageDetailList;