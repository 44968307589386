import { 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PackageType } from '@/types/package';

interface BasicTableProps {
  packages: PackageType[];
  pagination?: JSX.Element;
}

const BasicTable = ({ 
  packages, pagination,
}: BasicTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return(
    <TableContainer component={Paper} className='w-full border-solid rounded-[50px] border-gray-200 bg-red-200' style={{ borderRadius: 50 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center'>{t('package id')}</TableCell>
            <TableCell align='center'>{t('status')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {packages.map((item) => (
            <TableRow 
              key={item.id} 
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              className='cursor-pointer hover:bg-gray-200'
              onClick={() => navigate(`/package/${item.id}`)}
            >
              <TableCell align='center'>{item.id}</TableCell>
              <TableCell align='center'>{item.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Divider/>
      <div className='mr-2'>
        {pagination}
      </div>
    </TableContainer>
  );
}

export default BasicTable;