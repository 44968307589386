import Navbar from '../navbar';

const appIcon = require('../../assets/icon.png');

const Header = () => {
  return (
    <div>
      <Navbar />
      <div className="flex justify-center items-center">
        <div className="flex justify-between items-center mt-2">
          <img 
            src={appIcon} 
            alt="App Icon" 
            className="w-[250px] h-[70px] md:w-[600px] md:h-[180px]" 
          />
        </div>
      </div>
    </div>
  );
}

export default Header;