import { QueryDocumentSnapshot, FirestoreDataConverter, DocumentData } from "firebase/firestore";

export interface PackageLogType {
  id: string,
  message: string,
  date: Date,
}

export const PackageLogConverter: FirestoreDataConverter<PackageLogType> = {
  toFirestore: (data: PackageLogType) => {
    return {...data};
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) => {
    const data = snapshot.data();
    return {
      ...data,
      id: snapshot.id,
      date: data?.date.toDate(),
    } as PackageLogType
  }
}