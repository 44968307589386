import { useTranslation } from 'react-i18next';

const qrCodeImage1 = require('../../assets/wechat_qr_code_1.JPG');
const qrCodeImage2 = require('../../assets/wechat_qr_code_2.JPG');

const Footer = () => {

  const { t } = useTranslation();

  return (
    <div 
      className="flex flex-col justify-center items-center mb-[200px] md:mb-[250px]"
    >
      <div>
        <img 
          src={qrCodeImage1}
          alt="QR Code"
          className="w-[100px] h-[130px] md:w-[200px] md:h-[250px] mr-2" 
        />
        <img 
          src={qrCodeImage2}
          alt="QR Code"
          className="w-[100px] h-[130px] md:w-[200px] md:h-[250px]" 
        />
      </div>
      <p>{t('contact number')}: 09-977711116</p>
    </div>
  )
}

export default Footer;