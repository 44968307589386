import { useSigninCheck } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { useAuth } from 'reactfire';

import LanguageMenuButton from '../ui/LanguageMenuButton';

const Navbar = () => {

  const { t } = useTranslation();
  const auth = useAuth();
  const { status, data: signInCheckResult } = useSigninCheck();

  const isSignedIn = status === 'success' && signInCheckResult.signedIn;

  const navigate = useNavigate();

  const handleSignout = async () => {
    await auth.signOut();
    navigate('/');
  }

  return(
    <div className='flex justify-between items-center mt-2 mr-2 ml-2'>
      <Button onClick={() => navigate('/')}>{t('home')}</Button>
      
      <div className='flex'>
        <LanguageMenuButton />
        {
          isSignedIn ? <>
            <div className='mr-2'>
              <Button 
                variant='outlined'
                onClick={() => navigate('/packages')}
              >{ t('packages') }</Button>
            </div>
            {/* <Button 
              variant='outlined'
              onClick={() => navigate('/package/new')}
            >{ t('create package') }</Button> */}
            <Button 
              variant='outlined'
              onClick={handleSignout}
            >{ t('logout') }</Button>
          </>: null
        }
      </div>
    </div>
  )
}

export default Navbar;