import { PackageType } from '@/types/package';
import { CollectionReference, DocumentReference } from 'firebase/firestore';
import { read, utils } from 'xlsx';
import { packageLogsCollection, packagesDoc } from './db/dataPoint';
import { batchCreate, batchCreateWithId, logExist } from './db/functions';
import { PackageLogType } from '@/types/package-log';

export const convertExcelToJSON = (file: File) => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();

      reader.onload = (evt) => {
        const bstr = evt?.target?.result;
        const wb = read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const json = utils.sheet_to_json(ws, { header: 1 });
        resolve(json)
      };
      reader.readAsBinaryString(file);
    } catch (e) {
      reject(e)
    }
    
  })
}

export const uploadPackages = async (file: File, pendingStatus: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const json: any = await convertExcelToJSON(file);
      const documents: Array<{id: string, statuses: string[]}> = [];

      json.forEach(async (a: any, index: number) => {
        // First row is header
        if (index !== 0) {
          const [id, ...rest] = a
          id && documents.push({
            id: `${id}`,
            statuses: rest || []
          })
        }
      })

      const packagesToCreate: Array<
        { data: PackageType, ref: DocumentReference}
      > = [];

      documents.forEach((doc) => {
        // Update the Package also for status
        const lastStatus = doc.statuses[doc.statuses.length -1] || pendingStatus
        const newPackage = { id: doc.id, status: lastStatus} as PackageType
        const ref = packagesDoc(doc.id)
        packagesToCreate.push({
          data: newPackage,
          ref: ref
        })
      })

      if (packagesToCreate.length > 0) {
        // Create Packages
        await batchCreateWithId(packagesToCreate)
      }

      // Create Logs
      const logsToCreate: Array<{
        data: PackageLogType,
        ref: CollectionReference,
      }> = []

      for (const doc of documents) {
        for (const status of doc.statuses) {
          const newLog = { message: status } as PackageLogType
          const ref = packageLogsCollection(doc.id)
          const exist = await logExist(ref, status)
          if (!exist) {
            logsToCreate.push({
              data: newLog,
              ref: ref
            })
          }
        }
      }      

      if (logsToCreate.length > 0) {
        batchCreate(logsToCreate).then((response) => {
          resolve(response)
        })
      } else {
        resolve(true)
      }
    } catch (e) {
      reject(e)
    }
  })
}
