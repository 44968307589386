import Moment from 'moment';

const dateFormat = 'DD/MM/YYYY';
const dateTimeFormat = 'DD/MM/YYYY hh:mm A'

export const dateFormatter = {
  toString: (date: Date) => Moment(date).format(dateFormat),
  toDate: (input: string) => Moment(input, dateFormat).toDate(),
}

export const dateTimeFormatter = {
  toString: (date: Date) => Moment(date).format(dateTimeFormat),
  toDate: (input: string) => Moment(input, dateTimeFormat).toDate(),
}