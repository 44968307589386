import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSigninCheck } from 'reactfire';

interface WithProtectedRouteProps {}

const withProtectedRoute = <P extends WithProtectedRouteProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const WithProtectedRoute: React.FC<P> = (props) => {
    const navigate = useNavigate();
    const { status, data: signInCheckResult } = useSigninCheck();

    if (status === 'loading') {
      return (
        <div className='flex justify-center items-center mt-3'>
          <CircularProgress/>
        </div>
      )
    }

    if (signInCheckResult.signedIn === false) {
      navigate('/login');
    }

    return <WrappedComponent {...props} />;
  };

  return WithProtectedRoute;
};

export default withProtectedRoute;