import React, { useState, useRef } from 'react';
import { 
  ClickAwayListener,
  Button,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

enum Locale {
  EN = 'en',
  ZH = 'zh',
}

interface LocaleOption {
  label: string;
  value: Locale;
}

const localeList: Array<LocaleOption> = [
  {label: 'English', value: Locale.EN},
  {label: '中文', value: Locale.ZH},
]

const LanguageMenuButton = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { i18n } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const setLocale = (locale: string) => {
    i18n.changeLanguage(locale)
    // storeData('locale', locale)
  }

  const getLocale= () => {
    return localeList.find((l) => l.value === i18n.language)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    
    const target = event?.target as HTMLButtonElement
    switch (target?.textContent) {
      case 'English':
        setLocale(Locale.EN)
        break;
      case '中文':
        setLocale(Locale.ZH)
        break;
      default:
        break;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return(
    <Stack direction="row" spacing={2}>
      <div className='mr-2'>
        <Button
          ref={anchorRef}
          id="composition-button"
          variant='outlined'
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >{getLocale()?.label}</Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    {
                      localeList.map((locale) => (
                        <MenuItem key={locale.value} onClick={handleClose}>{locale.label}</MenuItem>
                      ))
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}

export default LanguageMenuButton;