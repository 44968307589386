import { useState, useCallback } from "react";
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { InputBase } from '@mui/material';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  cursor: 'pointer',
  pointerEvents: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

interface SearchBarProps {
  beginSearch: (query: string) => void;
  className?: string;
}

const SearchBar = ({ beginSearch, className }: SearchBarProps) => {
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleSearch = useCallback(() => {
    if (!searchQuery) {
      return;
    }
    beginSearch(searchQuery);
    setSearchQuery('');
  }, [searchQuery, beginSearch]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return(
    <Search className={`${className}`}>
      <SearchIconWrapper>
        <SearchIcon 
          onClick={() => handleSearch()}
          className="z-10"
        />
      </SearchIconWrapper>
      <StyledInputBase
        value={searchQuery}
        placeholder={`${t('search')}`}
        onKeyDown={onKeyPress}
        onChange={(e) => setSearchQuery(e.target.value)}
        onBlur={() => handleSearch()}
        inputProps={{ 'aria-label': 'search' }}
        className="bg-violet-100 rounded-3xl h-12 w-full"
      />
    </Search>
  )
}

export default SearchBar;