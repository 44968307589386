import { 
  useState, 
  useRef, 
  useEffect, 
  useCallback, 
} from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentSnapshot, getDoc } from 'firebase/firestore';
import { Backdrop, Button, CircularProgress } from '@mui/material';

import useFetchItems from '@/services/db/useFetchItems';
import useFetchItemsCount from '@/services/db/useFetchItemsCount';
import { packagesCollection, packagesDoc } from '@/services/db/dataPoint';
import { uploadFile } from '@/services/firebaseStorage';
import { uploadPackages } from '@/services/document';
import { TablePagination } from '@mui/material';
import { Helmet } from 'react-helmet';

import Searchbar from '@/components/ui/searchbar';
import BasicTable from '@/components/ui/table';
import { PackageType } from '@/types/package';
import withProtectedRoute from '@/components/withProtectedRoute';
import BasicSnackbar from '@/components/ui/snackbar';

const optionsPerPage = [5, 10, 25, 50, 100];

const PackagesPage = () => {
  const { t } = useTranslation();
  const [itemsCount, setItemsCount] = useState(0);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(optionsPerPage[0]);
  // const from = page * itemsPerPage;
  // const to = Math.min((page + 1) * itemsPerPage, itemsCount);
  const [items, setItems] = useState<PackageType[]>([]);
  const [uploading, setUploading] = useState(false);

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarVariant, setSnackbarVariant] = useState<'success' | 'error' | 'warning' | 'info'>('success');

  // Keep cursor in memory
  const cursor = useRef<Map<number, DocumentSnapshot>>(new Map());

  const { data, status } = useFetchItems({
    cursor: cursor.current.get(page),
    itemsPerPage: itemsPerPage,
    ref: packagesCollection,
    order: 'date',
    orderType: 'desc',
  })

  useEffect(() => {
    if (data) {
      setItems(data.docs.map((doc) => doc.data() as PackageType) ?? []);
    }
  }, [data])

  useFetchItemsCount({ ref: packagesCollection })().then((snapshot) => {
    setItemsCount(snapshot.data().count);
  })

  useEffect(() => {
    setPage(0);
  }, [itemsPerPage])

  const onPageChange = useCallback((event: React.MouseEvent<HTMLButtonElement> | null,nextPage: number) => {
    setPage((page) => {
      // firse, we save the last document as page's cursor
      cursor.current.set(
        nextPage,
        data.docs[data.docs.length - 1]
      );

      // then we update the state with the next page's number
      return nextPage;
    });
  }, [data])

  const handleFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setUploading(true);
      const file = e.target.files[0];
      uploadFile(file.name, file, 'documents')
      uploadPackages(file, t('pending')).then(() => {
        setUploading(false);
        setShowSnackbar(true);
        setSnackbarVariant('success');
        setSnackbarMessage(t('upload success') as string);
      }).catch((err) => {
        console.log(err);
        setUploading(false);
        setShowSnackbar(true);
        setSnackbarVariant('error');
        setSnackbarMessage(t('upload error') as string);
      })
    }
  }

  const searchPackage = (text: string) => {
    const packageRef = packagesDoc(text);
    getDoc(packageRef).then((doc) => {
      if (doc.exists()) {
        setItems([doc.data() as PackageType])
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
      }
    })
  }

  if (status === 'loading') {
    return (
      <div className='flex justify-center items-center mt-3'>
        <CircularProgress/>
      </div>
    )
  }

  return(
    <>
      <Helmet>
        <title>{t('packages')}</title>
      </Helmet>
      <div className='flex flex-col justify-center items-center ml-2 mr-2 mb-[100px]'>
        <div className='w-[300px] md:w-[550px]'>
          <div className='flex flex-row justify-between items-center mt-4 mb-2'>
            <div className='w-[200px] md:w-[400px]'>
              <Searchbar beginSearch={searchPackage} />
            </div>
            <label htmlFor="file-picker">
              <input id="file-picker" type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={handleFileSelection} style={{ display: 'none' }} />
              <Button variant='outlined' className='rounded-3xl' component='span'>
                {t('upload file')}
              </Button>
            </label>
          </div>

          {
            uploading ? (<Backdrop open={uploading}><CircularProgress /></Backdrop>) : 
            <BasicTable 
              packages={items as PackageType[]}
              pagination={<TablePagination
                component="div"
                count={itemsCount}
                page={page}
                onPageChange={(e, page) => onPageChange(e, page)}
                rowsPerPage={itemsPerPage}
                rowsPerPageOptions={optionsPerPage}
                onRowsPerPageChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))}
                labelRowsPerPage={t('rows per page')}
              />}
            />
          }
          
          <BasicSnackbar 
            open={showSnackbar}
            message={snackbarMessage}
            duration={1000}
            onClose={() => setShowSnackbar(false)}
            variant={snackbarVariant}
          />
        </div>
      </div>
    </>
  )
}

export default withProtectedRoute(PackagesPage);