import { DocumentData, FirestoreDataConverter, QueryDocumentSnapshot } from "firebase/firestore";


export interface PackageType {
  id: string,
  status: string,
  date?: Date,
  imageFilePath?: string,
  localImageFilePath?: string,
}

export const PackageConverter: FirestoreDataConverter<PackageType> = {
  toFirestore: (data: PackageType) => {
    return {...data};
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot<DocumentData>) => {
    const data = snapshot.data();
    return {
      ...data,
      id: snapshot.id,
      date: data?.date?.toDate(),
    } as PackageType
  }
}