import { useCallback } from 'react';

import {
  getCountFromServer,
  query,
  CollectionReference,
} from 'firebase/firestore';

export default function useFetchItemsCount(params: {
  ref: CollectionReference
}) {
  const { ref } = params;
  return useCallback(() => {
    const constraints: Array<any> = [];
    return getCountFromServer(
      query(ref, ...constraints)
    );
  }, [ref])

}