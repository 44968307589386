import { useTranslation } from 'react-i18next';

const Unauthorised = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center text-red-500">
      <h1>{t('user is unauthorised')}</h1>
    </div>
  );
};

export default Unauthorised;