import { useEffect, useState } from "react";
import { uploadFile } from "@/services/firebaseStorage";
import { updateDoc } from "@/services/db/functions";
import { packagesDoc } from "@/services/db/dataPoint";
import { getDoc } from "firebase/firestore";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { PackageType } from "@/types/package";
import PackageDetailList from "@/components/packageDetailList";
import { ParsedUrlQuery } from "querystring";
import BasicSnackbar from "@/components/ui/snackbar";
import { Button, CircularProgress } from "@mui/material";
import withProtectedRoute from "@/components/withProtectedRoute";
import { Helmet } from 'react-helmet';

const PackageDetails = () => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const { slug } = useParams();

  const [item, setItem] = useState<PackageType | undefined>(undefined);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getDoc(packagesDoc(`${slug}`)).then((doc) => {
      if (doc.exists()) {
        setItem(doc.data() as PackageType);
      }
      setLoading(false);
    })
  }, [slug]);

  const handleImagePicker = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          setItem({ ...item, localImageFilePath: event.target.result as string } as PackageType)
        }
      }

      uploadFile(file.name, file, 'images').then((result) => {
        updateDoc({ imageFilePath: result?.metadata.fullPath }, packagesDoc(slug as string)).then(() => {
          reader.readAsDataURL(file);
          setShowSnackbar(true);
        })
      })
    }
  }

  if (loading) {
    return (
      <div className='flex justify-center items-center mt-3'>
        <CircularProgress/>
      </div>
    )
  }

  return(
    <>
      <Helmet>
        <title>{t('package detail')}</title>
      </Helmet>
      <div className='flex flex-col items-center mb-[100px]'>
        {item && <>
          <PackageDetailList itemId={item.id} imageFilePath={item.imageFilePath} localImageFilePath={item.localImageFilePath} />
          <div className="mt-3">
            <label htmlFor='image-picker'>
              <input id='image-picker' type='file' accept='image/*' onChange={handleImagePicker} style={{ display: 'none' }} />
              <Button 
                variant='outlined'
                component='span'
              >{t('upload receipt')}</Button>
            </label>
          </div>
        </>
        }
        
        <BasicSnackbar 
          open={showSnackbar}
          message={t('successfully uploaded')}
          duration={1000}
          onClose={() => setShowSnackbar(false)}
        />
      </div>
    </>
  )
}

PackageDetails.getInitialProps = async ({ query }: {query: ParsedUrlQuery}) => {
  const { slug } = query;
  return { slug };
};

export default withProtectedRoute(PackageDetails);