import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import { 
  FirebaseAppProvider, 
  AuthProvider, 
  FirestoreProvider, 
  PerformanceProvider,
  AnalyticsProvider,
 } from 'reactfire';

import { auth, firestore, firebaseConfig, performance, analytics } from './config/firebase';

import en from './locales/en.json';
import zh from './locales/zh.json';

import Layout from './pages/Layout';
import Home from './pages/Home';
import Login from './pages/Login';
import Packages from './pages/Packages';
import PackageDetail from './pages/PackageDetail';
import Unauthorised from './pages/Unauthorised';

// Initialize i18next if it hasn't been initialized yet
if (!i18n.isInitialized) {
  i18n.use(initReactI18next).init({
    lng: 'zh',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: en
      },
      zh: {
        translation: zh
      },
    },
  });
}

const theme = createTheme();

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="login" element={<Login />} />
      <Route path="packages" element={<Packages />} />
      <Route path="package/:slug" element={<PackageDetail />} />
      <Route path="unauthorised" element={<Unauthorised />} />
    </Route>
  )
);

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={firestore}>
            <PerformanceProvider sdk={performance}>
              <AnalyticsProvider sdk={analytics}>
                <RouterProvider router={router}/>
              </AnalyticsProvider>
            </PerformanceProvider>
          </FirestoreProvider>
        </AuthProvider>
      </FirebaseAppProvider>
    </ThemeProvider>
  );
}

export default App;

