import { firestore } from '../../config/firebase';

import {
  doc,
  collection,
} from 'firebase/firestore';

import { PackageConverter } from '@/types/package';
import { PackageLogConverter } from '@/types/package-log';

const packagesPath = 'packages'
const packageLogsPath = 'logs'

export const packagesCollection = collection(firestore, packagesPath).withConverter(PackageConverter)
export const packagesDoc = (id: string) => doc(firestore, packagesPath, id).withConverter(PackageConverter)
export const packageLogsCollection = (packageId: string) => collection(packagesDoc(packageId), packageLogsPath).withConverter(PackageLogConverter)

