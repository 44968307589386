import { useState } from 'react';

import {
  getDoc,
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import { PackageType } from '@/types/package';
import { packagesDoc } from '@/services/db/dataPoint';

import PackageDetailList from '@/components/packageDetailList';
import { CircularProgress } from '@mui/material';
import Searchbar from '@/components/ui/searchbar';

import Footer from "../components/footer";

const Home = () => {
  
  const { t } = useTranslation();
  const [item, setItem] = useState<PackageType | undefined>(undefined);
  const [noItem, setNoItem] = useState<boolean>(false);
  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const searchPackage = (text: string) => {
    setSearchQuery(text);
    setSearching(true);
    setNoItem(false);
    const packageRef = packagesDoc(text);
    getDoc(packageRef).then((doc) => {
      if (doc.exists()) {
        setItem(doc.data() as PackageType)
      } else {
        setNoItem(true);
      }
      setSearching(false);
    })
  }

  return (
    <>
      <Helmet>
        <title>{t('home')}</title>
      </Helmet>
      <div className="flex flex-col justify-between h-screen">
        <main className='flex flex-col justify-center items-center mb-[100px]'>
          <div className='w-[300px] md:w-[550px] mt-3'>
            <Searchbar 
              beginSearch={searchPackage}
            />
          </div>
          {
            searching && <div className='flex justify-center items-center mt-[20px]'>
              <CircularProgress />
            </div>
          }
          {
            item && <PackageDetailList itemId={item.id} imageFilePath={item.imageFilePath} />
          }
          {
            noItem && (
              <div className="w-[300px] md:w-[550px]">
                <p className="text-center text-xl md:text-2xl">
                  { `${t('package cannot be found')} ${searchQuery}` }
                </p>
              </div>
            )
          }
        </main>
        <Footer />
      </div>
    </>
  )
}

export default Home;