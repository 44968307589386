import {
  Snackbar,
  Alert,
} from '@mui/material';

interface BasicSnackbarProps {
  open: boolean;
  duration?: number;
  message: string;
  onClose: () => void;
  variant?: 'success' | 'error' | 'warning' | 'info';
}

const BasicSnackbar = ({ open, duration = 2000, message, onClose, variant = 'success' } : BasicSnackbarProps) => {
  return(
    <Snackbar 
      open={open} 
      autoHideDuration={duration} 
      onClose={onClose}
      anchorOrigin={{ 
        vertical: 'bottom', horizontal: 'center'
      }}
    >
      <Alert severity={variant}>{message}</Alert>
    </Snackbar>
  )
}

export default BasicSnackbar;