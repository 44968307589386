import { 
  useFirestoreCollection,
} from 'reactfire';

import {
  CollectionReference,
  limit,
  orderBy,
  startAfter,
  query,
  DocumentSnapshot,
  OrderByDirection,
  QueryStartAtConstraint,
  QueryOrderByConstraint,
  QueryLimitConstraint,
} from 'firebase/firestore';

export default function useFetchItems(params: {
  cursor?: DocumentSnapshot,
  itemsPerPage: number,
  ref: CollectionReference,
  order?: string,
  orderType?: OrderByDirection,
}) {
  const {order = 'date', orderType = 'asc'} = params;
  const orderQuery = orderBy(order, orderType)

  // create default constraints
  const constraints: Array<QueryOrderByConstraint | QueryLimitConstraint | QueryStartAtConstraint> = [
    orderQuery,
    limit(params.itemsPerPage),
  ];

  // if cursor is not undefined (e.g. not initial query)
  // we pass it as a constarint
  if (params.cursor) {
    constraints.push(
      startAfter(params.cursor)
    );
  }

  const packagesQuery = query(params.ref, ...constraints);

  return useFirestoreCollection(packagesQuery)
}