import { storage } from '../config/firebase';
import { v4 as uuidv4 } from 'uuid';

import {
  ref,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';

export async function uploadFile(filename: string, file: any, path: string = '') {
  try {
    const fileRef = ref(storage, `${path}/${uuidv4()}_${filename}`);

    return await uploadBytes(fileRef, file)
  } catch (e) {
    console.log(e)
  }
}

export async function getFileUrl(path: string) {
  try {
    const fileRef = ref(storage, path);
    return await getDownloadURL(fileRef)
  } catch (e) {
    console.log(e)
  }
}