import { useAuth } from 'reactfire';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Google } from '@mui/icons-material';
import { useSigninCheck } from 'reactfire';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const provider = new GoogleAuthProvider();

const AUTHORISED_USERS = [
  'shubawai@gmail.com',
  'tunleng520@gmail.com',
]

const Login = () => {
  const { t } = useTranslation();
  const { status, data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const auth = useAuth()

  if (status === 'loading') {
    return <div className='flex justify-center items-center'>
      <CircularProgress />
    </div>
  }

  if (signInCheckResult.signedIn) {
    if (AUTHORISED_USERS.includes(`${signInCheckResult.user.email}`)) {
      navigate('/packages');
    } else {
      auth.signOut();
      navigate('/unauthorised');
    }
  }
  
  const handleGooglLogin = async () => {
    signInWithPopup(auth, provider).then((result) => {
      console.log(result)
    }).catch((error) => {
      console.log(error)
    })
  }

  return(
    <>
      <Helmet>
        <title>{t('login')}</title>
      </Helmet>
      {
        !signInCheckResult.signedIn &&
          <div className='flex justify-center mt-3'>
            <Button 
              variant='outlined' 
              onClick={handleGooglLogin}
              className='rounded-[30px]'
            >
              <Google className='mr-1'/>
              {t('google login')}
            </Button>
          </div>
      }
    </>
  )
}

export default Login;