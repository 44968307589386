import { dateTimeFormatter } from '@/services/dateFormatter';
import {
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@mui/material';

interface BasicListProps {
  items: any[];
  primary: string;
  secondary?: string;
}
const BasicList = ({items, primary, secondary}: BasicListProps) => {
  return(
    <List className="border-solid border-gray-200 rounded-[50px]">
      {items.map((item, index) => (
        <div key={item.id} className='pl-3'>
          <ListItem>
            <ListItemText 
              primary={item[primary]} 
              secondary={secondary && (secondary === 'date' ? dateTimeFormatter.toString(item[secondary]) : item[secondary])} 
            />
          </ListItem>
          { index !== items.length - 1 && <Divider/> }
        </div>
      ))}
    </List>
  )
}

export default BasicList;